  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Syne:wght@400..800&display=swap');

  html,
  body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    font-family: "Poppins", sans-serif;
    scroll-behavior: smooth;
  }

  .example-5 {
  position: relative;
}


/* For WebKit browsers */
::-webkit-scrollbar {
  width: 6px;
  /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Background of the track */
}

::-webkit-scrollbar-thumb {
  background: #ffde59;
  /* Color of the thumb */
  border-radius: 20px;
  /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the thumb on hover */
}

.example-5 .line {
  stroke-dasharray: 260;
  stroke-width: 3px;
  fill: transparent;
  stroke: rgba(78, 255, 13, 0.3);
  animation: svgAnimation 2.5s linear infinite;
}

@keyframes svgAnimation {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 1000;
  }
}
.moving-border-button {
  position: relative;
  display: inline-block; /* Ensures the button behaves like a block for the border */
  color: #333; /* Text color */
  overflow: hidden;
  z-index: 1;
}

.moving-border-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  background: linear-gradient(90deg, rgba(0, 123, 255, 0.3), rgba(0, 123, 255, 0) 50%, rgba(0, 123, 255, 0.3));
  z-index: -1;
  animation: move-border 2s linear infinite;
}

@keyframes move-border {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}



.example-5 {
  position: relative;
}


.example-5 .line {
  stroke-dasharray: 260;
  stroke-width: 10px;
  fill: transparent;
  stroke: rgba(243, 255, 79, 0.941);
  animation: svgAnimation 2.5s linear infinite;
}

@keyframes svgAnimation {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 1000;
  }
}
