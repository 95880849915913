.scroll {
    position: relative;
    display: flex;
    width: 100vw;
    overflow: hidden;
}

.scroll-content {
    display: flex;
    width: calc(200%);
    /* Ensures smooth scrolling */
    animation: scroll var(--t, 10s) linear infinite;
    /* Adds a default animation duration if --t is not set */
}

.scroll-content>div {
    display: flex;
    flex-shrink: 0;
}

.scroll-content div span {
    display: inline-flex;
    height: 80px;
    margin-right: 4rem;
}

.scroll-content div span img {
    height: 60px;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}

/* Custom shadow class */
.custom-shadow {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
    padding: 20px;
    background-color: white;
    /* Optional: Adjust other styles as needed */
}

.ticker-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    overflow: hidden;
    white-space: nowrap;
}

.ticker-number {
    display: inline-block;
    animation: tickerAnimation 5s linear infinite;
}

@keyframes tickerAnimation {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(-100%);
    }
}

.sectionNumbers {
    background-color: #f5f5f5;
    opacity: 1;
    background-image: radial-gradient(#ede738 2px, #fdfdfd 1.05px);
    background-size: 21px 21px;
}
.sectionTilte {
    background-color: #f5f5f5;
    opacity: 1;
    background-image: radial-gradient(#ede738 2px, #fdfdfd 1.05px);
    background-size: 21px 21px;
}

.sectionServices {
    background-color: #f5f5f5;
    opacity: 1;
    background-image: radial-gradient(#ede738 1px, #fdfdfd 1.05px);
    background-size: 21px 21px;
}

@keyframes shimmer {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -200% 0;
    }
}

.shimmer-animation {
    background-image: linear-gradient(110deg, transparent 45%, yellow 55%, transparent);
    background-size: 200% 100%;
    animation: shimmer 2s linear infinite;
}